// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import "modules"
import "controllers"
import 'css/site'
import 'bootstrap'
import 'bootstrap/js/dist/tooltip';

let onCarButtonClick = function() {
    const target = "#transport_car_ids";
    const currentValue = $(target).val();
    if (currentValue) {
        let values = currentValue.split(',');
        const buttonId = $(this).val();

        // remove car with given id
        for (let i = 0; i < values.length; i++) {
            if ( values[i] === buttonId) { values.splice(i, 1); }
        }
        const updatedValue = values.sort().join(',');
        $(target).val(updatedValue);
        $(target).change();
    }
};

// sends a request with the URI specified by data-uri
$(document).on('turbolinks:load', function() {
    $(".clickableRow").click(function() {
        window.document.location = $(this).attr("data-uri");
    });
});

// hide/show sections (using checkbox usually)
$(document).on('turbolinks:load', function() {
    $(".toggle-section").change(function() {
        const target = "#" + this.getAttribute("data-toggle-target");
        if (this.checked) {
            $(target).removeClass("hidden");
        }
        else {
            $(target).addClass("hidden");
        }
    });
});

// add selected value to input field
$(document).on('turbolinks:load', function() {
    $(".multi-select").click(function() {
        const addValue = this.getAttribute("data-target-value");
        const target = "#" + this.getAttribute("data-target");
        const currentValue = $(target).val();
        let values = [];
        if (currentValue) {
            values = currentValue.split(',');
        }
        if (!values.includes(addValue)) {
            values.push(addValue);
        }
        const updatedValue = values.sort().join(',');
        console.log(updatedValue);
        $(target).val(updatedValue);
        $(target).change();
    });
});

$(document).on('turbolinks:load', function() {
    $("#transport_car_ids").change(function() {

        $("#cars").html("");
        const currentValue = $(this).val();
        if (currentValue) {
            let values = currentValue.split(',');
            console.log("value = " + currentValue);
            for (let i = 0; i < values.length; i++) {
                let carButton = $("#car-button-template").clone();
                let id = values[i];
                carButton.prop("id", id);
                carButton.prop("value", id);
                carButton.prepend(id);
                carButton.appendTo( $("#cars") );
                carButton.removeClass("hidden");
            }
            $(".btn-car").click( onCarButtonClick );
        }
    });
});

$(document).on('turbolinks:load', function() {
    $(".btn-car").click( onCarButtonClick );
});


// show checkboxes for table rows of cars
$(document).on('turbolinks:load', function() {
    $("#create-transport").click(function() {
        const cars_selected = $(".mark-row-chk:checked").map(function() {
            return this.value;
        }).get().join();
        console.log(cars_selected);
    });
});

// mark table rows of cars in order to create a transport
$(document).on('turbolinks:load', function() {
    $(".mark-row-chk").change(function() {
        console.log("changed");
        const num_selected = $(".mark-row-chk:checked").length;
        const isTransportButtonDisabled = (num_selected == 0);
        $("#create-transport").prop( "disabled", isTransportButtonDisabled);
        $("#num_cars_selected").text(num_selected);
    });
});

// we wanna see the chosen file
$(document).on('turbolinks:load', function() {
    $(".custom-file-input").change(function() {
        if (this.files.length == 1) {
            const filename = this.files[0].name;
            $(this).parent().find('label').text(filename);
        }
    });
});

// enable bootstrap tooltips
$(document).on('turbolinks:load', function() {
    $('[data-toggle="tooltip"]').tooltip()
});
